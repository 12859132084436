import { InputAdornment, MenuItem, Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import BusinessIcon from "@material-ui/icons/Business";
import CancelIcon from "@material-ui/icons/Cancel";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import EditIcon from "@material-ui/icons/Edit";
import ImportEmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import HttpIcon from "@material-ui/icons/Http";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import LinkIcon from "@material-ui/icons/Link";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import ImportPhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import SaveIcon from "@material-ui/icons/Save";
import WebIcon from "@material-ui/icons/Web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import _ from "lodash";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { createLog } from "../../util/ActivityUtil";
import { validateLead } from "../../util/ValidationUtil";
import EventDialog from "../EventDialog";
import { withFirebase } from "../Firebase";
import NoteDialog from "../NoteDialog";
import styles from "./styles";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";
import * as ROUTES from "../../constants/routes";

const ReversedListItemText = withStyles(() => ({
  reverseListItemText: {
    display: "flex",
    flexDirection: "column-reverse",
    overflow: "hidden"
  }
}))(({ classes, ...other }) => {
  return <ListItemText className={classes.reverseListItemText} {...other} />;
});

const phoneUtil = PhoneNumberUtil.getInstance();

class LeadPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      events: null,
      notes: null,
      event: null,
      note: null,
      application: null,
      editing: false,
      linking: false,
      lead: props.lead,
      sentMail: [],
      errors: {}
    };
    this.originalLead = props.lead;
    this.log = createLog(props.firebase, props.authUser);
  }

  componentDidMount = () => {
    this.registerListeners(this.props.lead);
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.lead.id !== prevProps.lead.id ||
      this.props.lead.uid !== prevProps.lead.uid
    ) {
      this.setState({
        event: null,
        note: null,
        application: null,
        editing: false,
        linking: false,
        lead: this.props.lead
      });
      this.originalLead = this.props.lead;
      this.unregisterListeners();
      this.registerListeners(this.props.lead);
    }
  };

  registerListeners = lead => {
    this.registerApplicationSnapshotListener(lead);
    this.registerEventsSnapshotListener(lead);
    this.registerNotesSnapshotListener(lead);
    this.registerSentMailSnapshotListener(lead);
  };

  handleLeadTextChange = event => {
    this.setState({
      lead: { ...this.state.lead, [event.target.name]: event.target.value }
    });
  };

  handleAutoCompleteChange = (event, value) => {
    this.setState({
      lead: { ...this.state.lead, source: value ? value.name : null }
    });
  };

  handleEditClick = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleSaveLeadClick = () => {
    const result = validateLead(this.state.lead);
    if (result.isValid) {
      this.handleSaveLead(result.lead);
    } else {
      this.setState({ errors: result.errors });
    }
  };

  handleCancelEdit = () => {
    this.setState({ lead: this.props.lead, editing: false, errors: {} });
  };

  handleDeleteLeadClick = () => {
    this.handleDeleteLead(this.state.lead);
  };

  handleLinkApplicationClick = () => {
    this.setState({ linking: !this.state.linking });
  };

  handleSaveApplicationClick = () => {
    this.handleLinkApplication(this.state.lead);
    this.setState({ linking: false });
  };

  handleCancelLinking = () => {
    this.setState({ lead: this.props.lead, linking: false });
  };

  handleEventDialogOpen = event => () => {
    this.setState({ event });
  };

  handleNoteDialogOpen = note => () => {
    this.setState({ note });
  };

  componentWillUnmount = () => {
    this.unregisterListeners();
  };

  registerEventsSnapshotListener = lead => {
    const firestore = this.props.firebase.firestore;
    let q = query(
      collection(firestore, "events"),
      where("leadId", "==", lead.id)
    );
    if (
      !this.props.authUser.isAdmin &&
      !this.props.authUser.role === "support"
    ) {
      q = query(q, where("uid", "==", this.props.authUser.uid));
    }
    q = query(q, orderBy("scheduledAt", "asc"));
    this.unregisterEventsSnapshotListener = onSnapshot(q, snapshot => {
      const events = snapshot.docs.map(e => ({ id: e.id, ...e.data() }));
      this.setState({ events });
    });
  };

  registerNotesSnapshotListener = lead => {
    const firestore = this.props.firebase.firestore;
    let q = query(
      collection(firestore, "notes"),
      where("leadId", "==", lead.id)
    );
    if (
      !this.props.authUser.isAdmin &&
      !this.props.authUser.role === "support"
    ) {
      q = query(q, where("uid", "==", this.props.authUser.uid));
    }
    q = query(q, orderBy("createdAt", "desc"));
    this.unregisterNotesSnapshotListener = onSnapshot(q, snapshot => {
      const notes = snapshot.docs.map(e => ({ id: e.id, ...e.data() }));
      this.setState({ notes });
    });
  };

  registerApplicationSnapshotListener = lead => {
    if (lead.applicationId) {
      const firestore = this.props.firebase.firestore;
      onSnapshot(
        doc(firestore, "applications", lead.applicationId),
        snapshot => {
          const application = { id: snapshot.id, ...snapshot.data() };
          this.setState({ application });
        }
      );
    } else {
      this.setState({ application: null });
    }
  };

  registerSentMailSnapshotListener = lead => {
    const firestore = this.props.firebase.firestore;
    let l = doc(firestore, "leads", lead.id);
    let q = query(collection(firestore, "sentMail"), where("leadRef", "==", l));
    this.unregisterSentMailSnapshotListener = onSnapshot(q, snapshot => {
      const sentMail = snapshot.docs.map(e => ({ id: e.id, ...e.data() }));
      this.setState({ sentMail });
    });
  };

  unregisterListeners = () => {
    if (typeof this.unregisterEventsSnapshotListener === "function") {
      this.unregisterEventsSnapshotListener();
    }
    if (typeof this.unregisterNotesSnapshotListener === "function") {
      this.unregisterNotesSnapshotListener();
    }
    if (typeof this.unregisterApplicationSnapshotListener === "function") {
      this.unregisterApplicationSnapshotListener();
    }
    if (typeof this.unregisterSentMailSnapshotListener === "function") {
      this.unregisterSentMailSnapshotListener();
    }
  };

  hasMaximumCantRecycle = async lead => {
    if (lead.disposition === "Open - Hot - Can't Recycle") {
      const firestore = this.props.firebase.firestore;
      const snapshot = await getDocs(
        query(
          collection(firestore, "leads"),
          where("uid", "==", lead.uid),
          where("disposition", "==", "Open - Hot - Can't Recycle")
        )
      );
      if (snapshot.size >= 10) {
        alert("Maximum 'Open - Hot - Can't Recycle' for user.");
        return true;
      }
    }
  };

  handleDispositionChange = lead => async event => {
    const disposition = event.target.value;
    const updatedLead = { ...lead, disposition };

    if (await this.hasMaximumCantRecycle(updatedLead)) {
      return;
    }

    const group = this.props.metadata.dispositions[disposition].group;
    this.setState({ lead: updatedLead });

    const firestore = this.props.firebase.firestore;

    await setDoc(
      doc(firestore, "leads", lead.id),
      { disposition, group, dispositionUpdatedAt: new Date() },
      { merge: true }
    );

    this.log("Update Disposition", {
      oldValue: lead.disposition,
      newValue: disposition
    });

    this.props.onLeadUpdate && this.props.onLeadUpdate(updatedLead);
  };

  linkPaymentOptions = lead => () => {
    this.props.history.push({
      pathname: `${ROUTES.LINKS}`,
      state: { lead }
    });
  };

  sendAppLink = lead => async () => {
    this.setState({ sending: true });
    await httpsCallable(
      this.props.firebase.functions,
      "sendAppLink"
    )({
      leadId: lead.id,
      linkId: lead.linkId
    });
    this.setState({ sending: false });
  };

  sendQuote = lead => async () => {
    this.setState({ sending: true });
    await httpsCallable(
      this.props.firebase.functions,
      "sendQuote"
    )({
      leadId: lead.id,
      quoteId: lead.quoteId
    });
    this.setState({ sending: false });
  };

  handleAgentChange = lead => async event => {
    const updatedLead = { ...lead, uid: event.target.value };
    if (await this.hasMaximumCantRecycle(updatedLead)) {
      return;
    }
    const leadToSave = { uid: updatedLead.uid };
    if (!lead.uid) {
      leadToSave.assignedAt = new Date();
    }
    this.setState({ lead: updatedLead });
    await setDoc(
      doc(this.props.firebase.firestore, "leads", lead.id),
      leadToSave,
      { merge: true }
    );
    this.log("Update Lead User", {
      oldValue: lead && lead.uid ? lead.uid : null,
      newValue: event.target.value
    });
    await addDoc(
      collection(
        this.props.firebase.firestore,
        "users",
        event.target.value,
        "messages"
      ),
      {
        text: `${this.props.authUser.displayName} assigned you a new lead - ${lead.dba}.`
      }
    );
    this.props.onLeadUpdate && this.props.onLeadUpdate(updatedLead);
  };

  handleSaveLead = async lead => {
    this.setState({ lead: { ...lead }, errors: {}, editing: false });
    await setDoc(doc(this.props.firebase.firestore, "leads", lead.id), lead, {
      merge: true
    });
    this.log("Update Lead", { oldValue: this.originalLead, newValue: lead });
  };

  handleDeleteLead = async lead => {
    this.setState({ errors: {}, editing: false });
    await deleteDoc(doc(this.props.firebase.firestore, "leads", lead.id));
    this.log("Delete Lead", { value: this.originalLead });
    this.props.onClose();
  };

  handleLinkApplication = async lead => {
    this.setState({
      lead: { ...lead, applicationId: lead.applicationId, mid: lead.mid }
    });
    await setDoc(
      doc(this.props.firebase.firestore, "leads", lead.id),
      { applicationId: lead.applicationId || null, mid: lead.mid || null },
      { merge: true }
    );
    this.log("Link Application", {
      leadId: lead.id,
      applicationId: lead.applicationId,
      mid: lead.mid
    });
    if (typeof this.unregisterApplicationSnapshotListener === "function") {
      this.unregisterApplicationSnapshotListener();
    }
    this.registerApplicationSnapshotListener(lead);
  };

  handleLinkMerchantID = async lead => {
    this.setState({ lead: { ...lead, mid: lead.mid } });
    await setDoc(
      doc(this.props.firebase.firestore, "leads", lead.id),
      { mid: lead.mid },
      { merge: true }
    );
    this.log("Link Merchant ID", {
      leadId: lead.id,
      mid: lead.mid
    });
  };

  getFormattedPhone = phone => {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, "US");
      return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
    } catch (err) {
      return phone;
    }
  };

  render() {
    const { authUser, metadata, classes } = this.props;
    const {
      sending,
      events,
      notes,
      lead,
      event,
      note,
      editing,
      errors
    } = this.state;
    const groupedUsers = _.chain(metadata.users)
      .groupBy("role")
      .value();
    return (
      <Paper className={classes.paper}>
        <div className={classes.sticky}>
          <div className={classes.left}>
            {(authUser.isAdmin || authUser.role === "support") && (
              <div className={classes.field}>
                <TextField
                  id="agent"
                  label="Agent"
                  variant="outlined"
                  margin="normal"
                  value={lead.uid}
                  onChange={this.handleAgentChange(lead)}
                  select
                  fullWidth
                >
                  <ListSubheader disableSticky>Sales</ListSubheader>
                  {groupedUsers?.sales?.map(e => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {`${e.firstName} ${e.lastName}`}
                      </MenuItem>
                    );
                  })}
                  <ListSubheader disableSticky>Support</ListSubheader>
                  {groupedUsers?.support?.map(e => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {`${e.firstName} ${e.lastName}`}
                      </MenuItem>
                    );
                  })}
                  <ListSubheader disableSticky>Admin</ListSubheader>
                  {groupedUsers?.admin?.map(e => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {`${e.firstName} ${e.lastName}`}
                      </MenuItem>
                    );
                  })}
                  <ListSubheader disableSticky>Super</ListSubheader>
                  {groupedUsers?.super?.map(e => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {`${e.firstName} ${e.lastName}`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}

            <div className={classes.field}>
              <TextField
                id="disposition"
                label="Disposition"
                variant="outlined"
                margin="normal"
                value={lead.disposition}
                onChange={this.handleDispositionChange(lead)}
                select
                fullWidth
              >
                {Object.keys(metadata.dispositions)
                  .filter(e => metadata.dispositions[e].hide !== true)
                  .map(e => {
                    return (
                      <MenuItem
                        key={e}
                        value={e}
                        style={{
                          color: metadata.dispositions[e].color,
                          backgroundColor:
                            metadata.dispositions[e].backgroundColor
                        }}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <List
              component="div"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Basic Info
                  {editing ? (
                    <div>
                      {(authUser.isAdmin || authUser.role === "support") && (
                        <IconButton onClick={this.handleDeleteLeadClick}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={this.handleSaveLeadClick}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton onClick={this.handleCancelEdit}>
                        <CancelIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton onClick={this.handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  )}
                </ListSubheader>
              }
            >
              {!editing && (
                <Fragment>
                  {lead.name && (
                    <ListItem>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.name}
                        secondary={"Contact Name"}
                      />
                    </ListItem>
                  )}
                  {lead.dba && (
                    <ListItem>
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.dba}
                        secondary={"DBA"}
                      />
                    </ListItem>
                  )}
                  {lead.phone && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={this.getFormattedPhone(lead.phone)}
                        secondary={"Phone"}
                      />
                    </ListItem>
                  )}
                  {lead.phone2 && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={this.getFormattedPhone(lead.phone2)}
                        secondary={"Phone Alt"}
                      />
                    </ListItem>
                  )}
                  {lead.email && (
                    <ListItem>
                      <ListItemIcon>
                        <MailOutlineIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.email}
                        secondary={"Email"}
                      />
                    </ListItem>
                  )}
                  {lead.emailAlt && (
                    <ListItem>
                      <ListItemIcon>
                        <ImportEmailIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.emailAlt}
                        secondary={"Alternate Email"}
                      />
                    </ListItem>
                  )}
                  {lead.source && (
                    <ListItem>
                      <ListItemIcon>
                        <ImportantDevicesIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.source}
                        secondary={"Source"}
                      />
                    </ListItem>
                  )}
                  {lead.url && (
                    <ListItem>
                      <ListItemIcon>
                        <HttpIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={
                          <Tooltip title={lead.url} placement="top">
                            <span>{lead.url}</span>
                          </Tooltip>
                        }
                        secondary={"URL"}
                      />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon />
                    </ListItemIcon>
                    <ReversedListItemText
                      primary={lead.id}
                      secondary={"Lead ID"}
                    />
                  </ListItem>

                  {["super", "admin", "manager", "support"].includes(
                    authUser.role
                  ) && (
                    <Fragment>
                      {lead.phoneValidationResult && (
                        <ListItem>
                          <ListItemIcon>
                            <ImportPhoneAndroidIcon />
                          </ListItemIcon>
                          <ReversedListItemText
                            primary={`${lead.phoneValidationResult.valid.toString()}`}
                            secondary={"Phone Number Verified"}
                          />
                        </ListItem>
                      )}
                      {lead.emailValidationResult && (
                        <ListItem>
                          <ListItemIcon>
                            <ImportEmailIcon />
                          </ListItemIcon>
                          <ReversedListItemText
                            primary={`${lead.emailValidationResult.valid.toString()}`}
                            secondary={"Email Verified"}
                          />
                        </ListItem>
                      )}
                    </Fragment>
                  )}

                  {lead.currentlyProcessing && (
                    <ListItem>
                      <ListItemIcon>
                        <DoubleArrowIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.currentlyProcessing}
                        secondary={"Currently Processing"}
                      />
                    </ListItem>
                  )}
                  {lead.volumeProcessing && (
                    <ListItem>
                      <ListItemIcon>
                        <DoubleArrowIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.volumeProcessing}
                        secondary={"Volume Processing"}
                      />
                    </ListItem>
                  )}

                  {lead.howSoon && (
                    <ListItem>
                      <ListItemIcon>
                        <DoubleArrowIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.howSoon}
                        secondary={"How Soon"}
                      />
                    </ListItem>
                  )}
                  {lead.posInterest && (
                    <ListItem>
                      <ListItemIcon>
                        <DoubleArrowIcon />
                      </ListItemIcon>
                      <ReversedListItemText
                        primary={lead.posInterest}
                        secondary={"POS of Interest"}
                      />
                    </ListItem>
                  )}
                </Fragment>
              )}
            </List>
            {editing && (
              <Fragment>
                {(authUser.isAdmin || authUser.role === "support") && (
                  <div className={classes.field}>
                    <Autocomplete
                      id="source-autocomplete"
                      name="source"
                      options={metadata.sources}
                      classes={{
                        option: classes.option
                      }}
                      autoHighlight
                      value={
                        metadata.sources.find(e => e.name === lead.source) ??
                        null
                      }
                      onChange={this.handleAutoCompleteChange}
                      getOptionSelected={option => option.name}
                      getOptionLabel={option => option.name}
                      renderOption={option => {
                        return <span>{option.name}</span>;
                      }}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label="Source"
                            margin="normal"
                            value={lead.source}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password" // disable autocomplete and autofill
                            }}
                            fullWidth
                          />
                        );
                      }}
                    />
                  </div>
                )}
                <div className={classes.field}>
                  <TextField
                    id="name"
                    name="name"
                    label="Contact Name"
                    margin="normal"
                    variant="outlined"
                    value={lead.name}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="dba"
                    name="dba"
                    label="DBA"
                    margin="normal"
                    variant="outlined"
                    value={lead.dba}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    margin="normal"
                    variant="outlined"
                    value={lead.phone}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      )
                    }}
                    helperText={errors.phone}
                    error={Boolean(errors.phone)}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="phone2"
                    name="phone2"
                    label="Phone Alt"
                    margin="normal"
                    variant="outlined"
                    value={lead.phone2}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      )
                    }}
                    helperText={errors.phone2}
                    error={Boolean(errors.phone2)}
                    fullWidth
                  />
                </div>

                <div className={classes.field}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    value={lead.email}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>

                <div className={classes.field}>
                  <TextField
                    id="emailAlt"
                    name="emailAlt"
                    label="Alternate Email"
                    margin="normal"
                    variant="outlined"
                    value={lead.emailAlt}
                    onChange={this.handleLeadTextChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ImportEmailIcon />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>

                <div className={classes.field}>
                  <TextField
                    id="currentlyProcessing"
                    name="currentlyProcessing"
                    label="Currently Processing"
                    margin="normal"
                    variant="outlined"
                    value={lead.currentlyProcessing}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="volumeProcessing"
                    name="volumeProcessing"
                    label="Volume Processing"
                    margin="normal"
                    variant="outlined"
                    value={lead.volumeProcessing}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="howSoon"
                    name="howSoon"
                    label="How Soon"
                    margin="normal"
                    variant="outlined"
                    value={lead.howSoon}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="posInterest"
                    name="posInterest"
                    label="POS of Interest"
                    margin="normal"
                    variant="outlined"
                    value={lead.posInterest}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
              </Fragment>
            )}
          </div>
          <div className={classes.right}>
            <List
              component="div"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Payment Options
                </ListSubheader>
              }
            ></List>
            {lead.applicationUrl && (
              <Fragment>
                <div className={classes.pricingButtonContainer}>
                  <Tooltip title={lead.applicationUrl}>
                    <CopyToClipboard
                      text={lead.applicationUrl}
                      onCopy={() =>
                        alert(`Copied "${lead.applicationUrl}" to clipboard!`)
                      }
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        fullWidth
                        startIcon={<FilterNoneIcon />}
                      >
                        Copy App Url to Clipboard
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </div>
                <div className={classes.pricingButtonContainer}>
                  <Tooltip title={lead.email}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      fullWidth
                      startIcon={<MailOutlineIcon />}
                      onClick={this.sendAppLink(lead)}
                      disabled={sending}
                    >
                      Send App Link
                    </Button>
                  </Tooltip>
                </div>
              </Fragment>
            )}
            {lead.quoteUrl && (
              <Fragment>
                <div className={classes.pricingButtonContainer}>
                  <Tooltip title={lead.quoteUrl}>
                    <CopyToClipboard
                      text={lead.quoteUrl}
                      onCopy={() =>
                        alert(`Copied "${lead.quoteUrl}" to clipboard!`)
                      }
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        fullWidth
                        startIcon={<WebIcon />}
                      >
                        Copy Quote Url to Clipboard
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </div>
                <div className={classes.pricingButtonContainer}>
                  <Tooltip title={lead.email}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      fullWidth
                      startIcon={<MailOutlineIcon />}
                      onClick={this.sendQuote(lead)}
                      disabled={sending}
                    >
                      Send Quote
                    </Button>
                  </Tooltip>
                </div>
              </Fragment>
            )}
            <div className={classes.pricingButtonContainer}>
              <Button
                color={lead.applicationUrl ? "default" : "primary"}
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<LinkIcon />}
                onClick={this.linkPaymentOptions(lead)}
              >
                {lead.applicationUrl
                  ? "Replace Payment Options"
                  : "Link Payment Options"}
              </Button>
            </div>

            {/*
            <List
              component="div"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Application
                  {linking ? (
                    <div>
                      <IconButton onClick={this.handleSaveApplicationClick}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton onClick={this.handleCancelLinking}>
                        <CancelIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton onClick={this.handleLinkApplicationClick}>
                      <EditIcon />
                    </IconButton>
                  )}
                </ListSubheader>
              }
            >
              {!linking &&
                (application ? (
                  <Fragment>
                    <ListItem>
                      <ReversedListItemText
                        primary={application.leadId || "-"}
                        secondary={"IRIS ID"}
                      />
                    </ListItem>
                    <ListItem>
                      <ReversedListItemText
                        primary={lead.mid || "-"}
                        secondary={"Merchant ID"}
                      />
                    </ListItem>
                  </Fragment>
                ) : (
                  <ListItem button onClick={this.handleLinkApplicationClick}>
                    <ListItemIcon>
                      <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Link an application..." />
                  </ListItem>
                ))}
            </List>

            {linking && (
              <Fragment>
                <div className={classes.field}>
                  <TextField
                    id="applicationId"
                    name="applicationId"
                    label="Application ID"
                    margin="normal"
                    variant="outlined"
                    value={lead.applicationId}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    id="mid"
                    name="mid"
                    label="Merchant ID"
                    margin="normal"
                    variant="outlined"
                    value={lead.mid}
                    onChange={this.handleLeadTextChange}
                    fullWidth
                  />
                </div>
              </Fragment>
            )}
            */}
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Events
                  <IconButton onClick={this.handleEventDialogOpen({})}>
                    <AddIcon />
                  </IconButton>
                </ListSubheader>
              }
            >
              {events && events.length > 0 ? (
                events.map(e => (
                  <ListItem key={e.id} button>
                    <ListItemIcon>
                      <EventIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={e.title}
                      secondary={moment(e.scheduledAt.toDate()).format(
                        "L h:mm A"
                      )}
                      style={{
                        textDecoration: e.complete ? "line-through" : "none"
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={this.handleEventDialogOpen(e)}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <ListItem button onClick={this.handleEventDialogOpen({})}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add an event..." />
                </ListItem>
              )}
            </List>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Notes
                  <IconButton onClick={this.handleNoteDialogOpen({})}>
                    <AddIcon />
                  </IconButton>
                </ListSubheader>
              }
            >
              {notes && notes.length > 0 ? (
                notes.map(e => (
                  <ListItem
                    key={e.id}
                    button
                    onClick={this.handleNoteDialogOpen(e)}
                  >
                    <ListItemIcon>
                      <CommentIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={e.text}
                      secondary={moment(e.createdAt.toDate()).format(
                        "L h:mm A"
                      )}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem button onClick={this.handleNoteDialogOpen({})}>
                  <ListItemIcon>
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add a note..." />
                </ListItem>
              )}
            </List>
            {/* 
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
              subheader={
                <ListSubheader
                  className={classes.listSubheader}
                  component="div"
                >
                  Sent Email
                </ListSubheader>
              }
            >
              {sentMail && sentMail.length > 0 ? (
                sentMail.map(e => (
                  <ListItem key={e.id}>
                    <ListItemIcon>
                      <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText
                      secondary={moment(e.createdAt.toDate()).format(
                        "L h:mm A"
                      )}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No Sent Emails" />
                </ListItem>
              )}
            </List>
*/}
            {event && (
              <EventDialog
                open={true}
                lead={lead}
                event={event}
                onClose={this.handleEventDialogOpen(null)}
              />
            )}
            {note && (
              <NoteDialog
                open={true}
                lead={lead}
                note={note}
                onClose={this.handleNoteDialogOpen(null)}
              />
            )}
          </div>
        </div>
      </Paper>
    );
  }
}

export default compose(
  withFirebase,
  withRouter,
  withStyles(styles),
  connect(state => ({
    authUser: state.user,
    metadata: state.metadata
  }))
)(LeadPanel);
