import React from "react";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EventIcon from "@material-ui/icons/Event";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import { green, red } from "@material-ui/core/colors";
import LinkIcon from '@material-ui/icons/Link';

import moment from "moment";
import { Tooltip } from "@material-ui/core";

const phoneUtil = PhoneNumberUtil.getInstance();

export default {
  user: null,
  messages: [],
  metadata: {},
  events: [],
  applications: {
    columns: [
      {
        id: "id",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Application ID"
      },
      {
        id: "createdAt",
        visible: true,
        selector: row => {
          return (row.createdAt && row.createdAt.toDate)? moment(row.createdAt.toDate()).format("L h:mm A") : '';
        },
        numeric: false,
        sortable: true,
        export: true,
        label: "Date Created"
      },
      {
        id: "doingBusinessAs",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "DBA"
      },
      {
        id: "paysafeApplicationId",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Paysafe ID"
      },
      {
        id: "applicationStatus",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        selector: row => {
          return row.applicationStatus || row.status;
        },
        label: "Application Status"
      },
      /*
      {
        id: "pin",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Pin",
        selector: (row, metadata) => {
          return row.handoff?.Pin
        },
      },
      */
      {
        id: "completedAt",
        visible: true,
        selector: row => {
          return row.completedAt
            ? moment(row.completedAt.toDate()).format("L h:mm A")
            : "";
        },
        numeric: false,
        sortable: true,
        export: true,
        label: "Date Submitted"
      },
      {
        id: "archived",
        visible: true,
        selector: row => {
          return row.archived ? (
            <ArchiveIcon color="primary" />
          ) : (
            <ArchiveIcon color="disabled" />
          );
        },
        numeric: false,
        sortable: false,
        export: false,
        label: "Archived"
      },
      {
        id: "agent",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        selector: (row, metadata) => {
          return row.uid && metadata
            ? metadata.users && metadata.users[row.uid]
              ? `${metadata.users[row.uid].firstName || ""} ${
                  metadata.users[row.uid].lastName || ""
                }`
              : row.agent
            : row.agent;
        },
        label: "Agent"
      },
      {
        id: "exported",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        selector: row => {
          return row.klaviyoId ? (
            <a href={"https://www.klaviyo.com/profile/" + row.klaviyoId} target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </a>
          ) : '';
        },
        label: "Exported"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "createdAt",
    rowsPerPage: 100,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null,
    where: null
  },
  leads: {
    columns: [
      {
        id: "createdAt",
        visible: true,
        selector: row => {
          return row.createdAt
            ? moment(row.createdAt.toDate()).format("L h:mm A")
            : "";
        },
        numeric: false,
        sortable: true,
        export: true,
        label: "Date Created"
      },
      {
        id: "id",
        visible: false,
        selector: row => {
          return `${row.id.substring(0, 6)}...`;
        },
        numeric: false,
        sortable: false,
        export: true,
        label: "Lead ID"
      },
      {
        id: "doNotContact",
        visible: true,
        selector: row => {
          return row.doNotContact ? (
            <Tooltip title="Do not contact">
              <UnsubscribeIcon style={{ fill: "red" }} />
            </Tooltip>
          ) : (
            ""
          );
        },
        numeric: false,
        sortable: false,
        export: false,
        label: ""
      },
      {
        id: "source",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Source"
      },
      {
        id: "email",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Email",
        showCopyButton: true
      },
      {
        id: "email_valid",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Email Valid",
        selector: row => {
          return row.emailValidationResult
            ? row.emailValidationResult.valid.toString()
            : "";
        }
      },
      {
        id: "dba",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "DBA"
      },
      {
        id: "duplicate",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Duplicate"
      },
      {
        id: "name",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Name"
      },
      {
        id: "phone",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Phone",
        selector: row => {
          try {
            const number = phoneUtil.parseAndKeepRawInput(row.phone, "US");
            return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
          } catch (err) {
            return row.phone;
          }
        }
      },
      {
        id: "phone_valid",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Phone Valid",
        selector: row => {
          return row.phoneValidationResult
            ? row.phoneValidationResult.valid.toString()
            : "";
        }
      },
      {
        id: "user",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        showAdminOnly: true,
        label: "User",
        selector: (row, metadata) => {
          return metadata.users && metadata.users[row.uid]
            ? `${metadata.users[row.uid].firstName} ${
                metadata.users[row.uid].lastName
              }`
            : "";
        }
      },
      {
        id: "events",
        visible: true,
        selector: row => {
          return row.events > 0 ? (
            <EventIcon color="primary" />
          ) : (
            <EventIcon color="disabled" />
          );
        },
        numeric: false,
        sortable: true,
        export: false,
        label: "Events"
      },
      {
        id: "disposition",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Disposition",
        selector: (row, state) => {
          return state.dispositions ? (
            <Chip
              label={row.disposition}
              style={{
                backgroundColor:
                  state.dispositions[row.disposition]?.backgroundColor ||
                  "#FFFFFF",
                color: state.dispositions[row.disposition]?.color || "#000000"
              }}
            />
          ) : (
            row.disposition
          );
        }
      },
      {
        id: "exported",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        selector: row => {
          return row.klaviyoId ? (
            <a href={"https://www.klaviyo.com/profile/" + row.klaviyoId} target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </a>
          ) : '';
        },
        label: "Exported"
      },
      {
        id: "dispositionUpdatedAt",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Disposition Updated At"
      },
      {
        id: "url",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "URL"
      },
      {
        id: "campaign",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Campaign",
        selector: (row, state) => {
          const source = state.sources.find(s => s.name === row.source);
          return source?.campaign;
        }
      },
      {
        id: "ipAddress",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "IP Address"
      },
      {
        id: "isMobile",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "Is Mobile"
      },
      {
        id: "utmCampaign",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "UTM Campaign"
      },
      {
        id: "utmContent",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "UTM Content"
      },
      {
        id: "utmMedium",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "UTM Medium"
      },
      {
        id: "utmSource",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "UTM Source"
      },
      {
        id: "utmTerm",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "UTM Term"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "createdAt",
    rowsPerPage: 100,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null,
    where: null
  },
  sources: {
    columns: [
      {
        id: "name",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Name"
      },
      {
        id: "active",
        visible: true,
        selector: row => {
          return row.active ? (
            <FiberManualRecordIcon style={{ color: green[500] }} />
          ) : (
            <FiberManualRecordIcon style={{ color: red[500] }} />
          );
        },
        numeric: false,
        sortable: false,
        export: false,
        label: "Active"
      }
    ],
    data: [],
    order: "asc",
    orderBy: "name",
    rowsPerPage: 100,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null,
    where: null
  },
  campaigns: {
    columns: [
      {
        id: "name",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Name"
      },
      {
        id: "campaignType",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Type"
      },
      {
        id: "description",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Description"
      },
      {
        id: "sources",
        selector: (row) => <div>{row.sources && row.sources.join(', ')}</div>,
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Sources"
      },
    ],
    data: [],
    order: "asc",
    orderBy: "name",
    rowsPerPage: 100,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null,
    where: null
  },
  users: {
    columns: [
      {
        id: "id",
        visible: false,
        numeric: false,
        sortable: false,
        export: true,
        label: "User ID"
      },
      {
        id: "photoURL",
        visible: true,
        selector: row => {
          return <Avatar alt={`${row.id}`} src={row.photoURL} />;
        },
        numeric: false,
        sortable: true,
        export: true,
        label: ""
      },
      {
        id: "firstName",
        visible: true,
        selector: row => {
          return `${row.firstName || ""} ${row.lastName || ""}`;
        },
        numeric: false,
        sortable: true,
        export: true,
        label: "Name"
      },
      {
        id: "role",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Role"
      },
      {
        id: "email",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Email"
      },
      {
        id: "irisUserId",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "IRIS User ID"
      },
      {
        id: "createdAt",
        visible: true,
        selector: row => {
          return moment(row.createdAt.toDate()).format("L h:mm A");
        },
        numeric: false,
        sortable: true,
        export: true,
        label: "Date Created"
      },
      {
        id: "active",
        visible: true,
        selector: row => {
          return row.active ? (
            <FiberManualRecordIcon style={{ color: green[500] }} />
          ) : (
            <FiberManualRecordIcon style={{ color: red[500] }} />
          );
        },
        numeric: false,
        sortable: true,
        export: false,
        label: "Active"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "createdAt",
    rowsPerPage: 10,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null,
    where: null
  }
};
