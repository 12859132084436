import React, { Fragment, PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  TextField,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  MenuItem
} from "@material-ui/core";

import Title from "../../Title";

import ZipTax from "../../../../api/ZipTax";

import styles from "../styles";

class Cart extends PureComponent {
  state = {
    taxes: 0.0,
    loading: false
  };
  componentDidMount = async () => {
    const { link } = this.props.application;
    this.setState({ loading: true });
    const results = await ZipTax.getTaxes(this.props.application.businessZip);
    if (results.rCode === 100 && results.results.length) {
      const taxes = (results.results[0].taxSales * 100).toFixed(2);
      this.setState({ taxes, loading: false }, () => {
        const pricing = {
          subtotal: parseFloat(this.getSubtotal()),
          subtotalWithDiscount: parseFloat(this.getSubtotalWithDiscount()),
          discount:
            parseFloat(this.getSubtotal()) -
            parseFloat(this.getSubtotalWithDiscount()),
          taxes: parseFloat(this.getTaxes()),
          taxRate: parseFloat(taxes),
          orderTotal: parseFloat(this.getOrderTotal()),
          downPayment: parseFloat(this.getDownPayment()),
          balance: parseFloat(this.getBalance()),
          shipping: parseFloat(link?.shipping || 0),
          additionalFee: parseFloat(link?.additionalFee || 0)
        };
        this.props.setApplicationPricing(pricing);
      });
    } else {
      // TODO: This should throw an error
    }
    this.setState({ loading: false });
  };

  getSubtotal = () => {
    let subtotal = 0;
    if (this.props.application.link) {
      subtotal = this.props.application.link.equipment.reduce(
        (previous, current) =>
          parseFloat(current.price) * current.quantity + previous,
        0
      );
    }
    return subtotal;
  };

  getSubtotalWithDiscount = () => {
    if (!this.props.application.link) {
      return 0;
    }

    const { discountAmount, discountPercent } = this.props.application.link;
    let subtotal = this.getSubtotal();
    if (discountAmount) {
      subtotal = subtotal - parseFloat(discountAmount);
    }
    if (discountPercent) {
      subtotal = subtotal - subtotal * (parseFloat(discountPercent) / 100);
    }
    return subtotal;
  };

  getTaxes = () => {
    if (!this.props.application.link) {
      return 0;
    }
    const subtotal = this.getSubtotalWithDiscount();
    const { includeTaxes } = this.props.application.link;
    if (includeTaxes && subtotal && this.state.taxes) {
      return subtotal * (this.state.taxes / 100);
    } else {
      return 0.0;
    }
  };

  getOrderTotal = () => {
    if (!this.props.application.link) {
      return 0;
    }

    const subtotal = this.getSubtotalWithDiscount();
    const { shipping, additionalFee } = this.props.application.link;

    const orderTotal =
      parseFloat(subtotal) +
      this.getTaxes() +
      parseFloat(shipping || "0.00") +
      parseFloat(additionalFee || "0.00");
    return orderTotal.toFixed(2);
  };

  getDownPayment = () => {
    return (parseFloat(this.props.application.link?.downPayment) || 0) // (this.getOrderTotal() || 0) / (this.props.application.link.paymentPlan || 1)
      .toFixed(2);
  };

  getBalance = () => {
    return (
      parseFloat(this.getOrderTotal()) - parseFloat(this.getDownPayment())
    ).toFixed(2);
  };

  render() {
    const { loading } = this.state;
    const {
      application,
      onApplicationTextChange,
      errors,
      classes
    } = this.props;

    const { link } = this.props.application;

    return (
      application.withoutPayment ? 
      <div className={classes.root}>
        <Title
          h1={"Payment Info"}
          h2={
            "Payment information is not required for this application."
          }
        />
      </div>
      :
      <div className={classes.root}>
        <Title
          h1={"Payment Info"}
          h2={
            "Please review the items in your cart and add your payment information to proceed."
          }
        />
        <div className={classes.sectionForm}>
          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
            Payment Information
          </Typography>
          <Divider className={classes.divider} />
          {(!link || link?.paymentType !== "ACH") ? (
            <Fragment>
              <div className={classes.inputContainer}>
                <TextField
                  id="creditCardNumber"
                  label="Card Number"
                  placeholder="0000 0000 0000 0000"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.creditCardNumber || ""}
                  onChange={onApplicationTextChange("creditCardNumber")}
                  error={!!errors.creditCardNumber}
                  helperText={errors.creditCardNumber}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="creditCardType"
                  label="Card Type"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.creditCardType || ""}
                  onChange={onApplicationTextChange("creditCardType")}
                  error={!!errors.creditCardType}
                  helperText={errors.creditCardType}
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={"visa"}>Visa</MenuItem>
                  <MenuItem value={"mastercard"}>MasterCard</MenuItem>
                  <MenuItem value={"amex"}>AMEX</MenuItem>
                  <MenuItem value={"discover"}>Discover</MenuItem>
                </TextField>
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="creditCardExpireDate"
                  label="Expiration Date"
                  placeholder="MM/YY"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.creditCardExpireDate || ""}
                  onChange={onApplicationTextChange("creditCardExpireDate")}
                  error={!!errors.creditCardExpireDate}
                  helperText={errors.creditCardExpireDate}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="creditCardCvv"
                  label="Card Security Code (CVV)"
                  placeholder="123"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.creditCardCvv || ""}
                  onChange={onApplicationTextChange("creditCardCvv")}
                  error={!!errors.creditCardCvv}
                  helperText={errors.creditCardCvv}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="billingName"
                  label="Billing Name"
                  placeholder="Name on Credit Card"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.billingName}
                  onChange={onApplicationTextChange("billingName")}
                  error={!!errors.billingName}
                  helperText={errors.billingName}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="billingAddress"
                  label="Billing Address"
                  placeholder="Street Address"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.billingAddress}
                  onChange={onApplicationTextChange("billingAddress")}
                  error={!!errors.billingAddress}
                  helperText={errors.billingAddress}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="billingCity"
                  label="City"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.billingCity}
                  onChange={onApplicationTextChange("billingCity")}
                  error={!!errors.billingCity}
                  helperText={errors.billingCity}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="billingState"
                  label="State"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.billingState}
                  onChange={onApplicationTextChange("billingState")}
                  error={!!errors.billingState}
                  helperText={errors.billingState}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    maxLength: 2
                  }}
                />
                <TextField
                  id="billingZip"
                  label="Zip Code"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.billingZip}
                  onChange={onApplicationTextChange("billingZip")}
                  error={!!errors.billingZip}
                  helperText={errors.billingZip}
                  margin="normal"
                  fullWidth
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.inputContainer}>
                <TextField
                  id="paymentStatementName"
                  label="Name on Bank Statement"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.paymentStatementName || ""}
                  onChange={onApplicationTextChange("paymentStatementName")}
                  error={!!errors.paymentStatementName}
                  helperText={errors.paymentStatementName}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="paymentBankName"
                  label="Bank Name"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.paymentBankName || ""}
                  onChange={onApplicationTextChange("paymentBankName")}
                  error={!!errors.paymentBankName}
                  helperText={errors.paymentBankName}
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="paymentAccountNumber"
                  label="Bank Account Number"
                  placeholder="000000000"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.paymentAccountNumber || ""}
                  onChange={onApplicationTextChange("paymentAccountNumber")}
                  error={!!errors.paymentAccountNumber}
                  helperText={errors.paymentAccountNumber}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="paymentRoutingNumber"
                  label="Routing Number"
                  placeholder="000000000"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.paymentRoutingNumber || ""}
                  onChange={onApplicationTextChange("paymentRoutingNumber")}
                  error={!!errors.paymentRoutingNumber}
                  helperText={errors.paymentRoutingNumber}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="paymentAccountType"
                  label="Account Type"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.paymentAccountType || ""}
                  onChange={onApplicationTextChange("paymentAccountType")}
                  error={!!errors.paymentAccountType}
                  helperText={errors.paymentAccountType}
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={"checking"}>Checking</MenuItem>
                  <MenuItem value={"savings"}>Savings</MenuItem>
                </TextField>
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="branchCity"
                  label="City"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.branchCity}
                  onChange={onApplicationTextChange("branchCity")}
                  error={!!errors.branchCity}
                  helperText={errors.branchCity}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="branchState"
                  label="State"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.branchState}
                  onChange={onApplicationTextChange("branchState")}
                  error={!!errors.branchState}
                  helperText={errors.branchState}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    maxLength: 2
                  }}
                />
                <TextField
                  id="branchZip"
                  label="Zip Code"
                  variant="outlined"
                  className={classes.sideMargins}
                  value={application.branchZip}
                  onChange={onApplicationTextChange("branchZip")}
                  error={!!errors.branchZip}
                  helperText={errors.branchZip}
                  margin="normal"
                  fullWidth
                />
              </div>
            </Fragment>
          )}
          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
            Order Summary
          </Typography>
          <Divider className={classes.divider} />
          <List>
            <ListItem>
              <ListItemText primary="Products" />
            </ListItem>
            <List component="div" disablePadding>
              {link && link.equipment.map(e => {
                return (
                  <ListItem key={e.id} className={classes.nested}>
                    <ListItemText
                      primary={e.name}
                      secondary={`Quantity: ${e.quantity}`}
                    />
                    <ListItemText
                      className={classes.alignRight}
                      primary={`$${(e.price * e.quantity).toFixed(2)}`}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Discount" />
              <ListItemText
                className={classes.alignRight}
                primary={`($${(
                  this.getSubtotal() - this.getSubtotalWithDiscount()
                ).toFixed(2)})`}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Subtotal" />
              <ListItemText
                className={classes.alignRight}
                primary={`$${this.getSubtotalWithDiscount().toFixed(2)}`}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Taxes" />
              <ListItemText
                className={classes.alignRight}
                primary={`$${this.getTaxes().toFixed(2)}`}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Shipping" />
              <ListItemText
                className={classes.alignRight}
                primary={`$${parseFloat(link?.shipping || 0).toFixed(2)}`}
              />
            </ListItem>
            {link?.additionalFee && (
              <Fragment>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary={link?.additionalFeeLabel} />
                  <ListItemText
                    className={classes.alignRight}
                    primary={`$${parseFloat(link?.additionalFee || 0).toFixed(
                      2
                    )}`}
                  />
                </ListItem>
              </Fragment>
            )}
            <Divider component="li" />
            <ListItem>
              <ListItemText
                primary="Order Total"
                secondary={
                  link?.paymentType && link?.paymentPlan
                    ? `${link.paymentType} - ${link.paymentPlan} Month Term`
                    : ""
                }
              />
              <ListItemText
                className={classes.alignRight}
                primary={`$${this.getOrderTotal()}`}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Down Payment" />
              <ListItemText
                className={classes.alignRight}
                primary={`($${this.getDownPayment()})`}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText primary="Balance Due" />
              <ListItemText
                className={classes.alignRight}
                primary={`$${this.getBalance()}`}
              />
            </ListItem>
          </List>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(Cart);
