import React, { PureComponent, Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Title from "../../Title";
import styles from "../styles";
import fields from "./fields";
import { CustomPricingType } from './pricingType';
import { compose } from "recompose";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withFirebase } from "../../../Firebase";
import {
  getDocs,
  collection
} from "@firebase/firestore";

import {
  Checkbox,
  FormGroup,
  Typography,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";

const { commonFields, customCategories, getFields } = fields;

const allSoldProductTypesOptions = ['Option 1', 'Option 2', 'Option 3'];

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

class Pricing extends PureComponent {
  state = {
    pricingOption: CustomPricingType.ICPlus,
    soldProductTypesValue: '',
    soldProductTypesInputValue: '',
    soldProductTypesOptions: [],
    productTypesOptions: [],
    allSoldProductTypesOptions
  };

  componentDidMount = async () => {
    const firestore = this.props.firebase.firestore;
    const productTypesSnapshot = await getDocs(collection(firestore, "paysafeApiProductsAndServices"));
    const productTypesOptions = productTypesSnapshot.docs.map(doc => (doc.data().option));
    this.setState({ productTypesOptions });
  };

  handleSoldProductTypesInputChange = (event, newInputValue) => {
    console.log('handleSoldProductTypesInputChange', newInputValue);
    this.setState({
      //soldProductTypesInputValue: newInputValue,
      //soldProductTypesOptions: allSoldProductTypesOptions.filter(option => option.toLowerCase().includes(newInputValue.toLowerCase())),
    });
  };

  handleSoldProductTypesChange = function (event, newValue) {
    console.log('handleSoldProductTypesChange', newValue);
  };


  handleRadioChange = (event) => {
    const {
      onApplicationTextChange,
    } = this.props;
    onApplicationTextChange('customPricingType')(event);
    this.setState({
      pricingOption: event.target.value,
    });
  };

  render() {
    const {
      application,
      onApplicationTextChange,
      onApplicationAutocompleteChange,
      onCheckChange,
      errors,
      classes,
    } = this.props;

    console.log('application.soldProductTypes', application.soldProductTypes);
    const { pricingOption } = this.state;

    const N = 2; // Number of TextFields in a row

    const initPricingField = (application, fieldName, fieldValue) => {
      if (!application.customPricing) {
        application.customPricing = {};
        application.customPricingType = 'IC+';
      }
      if (application.customPricing[fieldName] === undefined) {
        application.customPricing[fieldName] = fieldValue;
      }
    }

    const hasTerminals = (application) => {
        return application.equipment.some(item => item.EquipmentTypeID === 'TM');
    }

    function getFieldTextInput(field, nameOnly) {
      const customPricing = application['customPricing'] || {};
      initPricingField(application, field.inputName, customPricing[field.inputName] || field.defaultValue);

      return <TextField
        key={field.inputName}
        id={field.inputName}
        label={nameOnly ? `${field.name} ${field.valueType}` : `${field.name} ${field.valueType} / ${field.category}`}
        placeholder={`Enter ${field.name}`}
        variant="outlined"
        className={classes.sideMargins}
        margin="normal"
        fullWidth
        value={customPricing[field.inputName]}
        onChange={onApplicationTextChange(field.inputName, 'customPricing')}
        error={!!errors[field.inputName]}
        helperText={errors[field.inputName]}
        type="number"
        inputProps={{
          min: "0",
        }}
      />
    }

    return (
      <div className={classes.root}>
        <Title h1={"Credit card sales and Pricing"} />
        <div className={classes.sectionForm}>

        <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
          Options
        </Typography>
        <div className={classes.inputContainer}>
          <FormGroup className={classes.differentCheckboxContainer} row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={application.runCreditReport}
                  onChange={onCheckChange("runCreditReport")}
                  value="runCreditReport"
                />
              }
              label="Run credit report"
            />
          </FormGroup>
        </div>


        <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
          Terminal info
        </Typography>

        <div className={classes.inputContainer}>
          <FormGroup className={classes.differentCheckboxContainer} row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={application.reprogram}
                  onChange={onCheckChange("reprogram")}
                  value="reprogram"
                />
              }
              label="Reprogram"
            />
          </FormGroup>
        </div>
        {!application.reprogram && hasTerminals(application) && application.customPricingType == 'Flat' && (
          <div className={classes.inputContainer}>
            <TextField
              id="terminalsBilling"
              label="Terminals Billing"
              variant="outlined"
              className={classes.sideMargins}
              value={application.terminalsBilling || ""}
              onChange={onApplicationTextChange("terminalsBilling")}
              error={!!errors.terminalsBilling}
              helperText={errors.terminalsBilling}
              margin="normal"
              select
              fullWidth
            >
              {[
                { ID: "AgentACH", Name: "Agent ACH" },
                { ID: "AgentCreditCard", Name: "Agent Credit Card" },
                { ID: "MerchantACH", Name: "Merchant ACH" },
                { ID: "MerchantCreditCard", Name: "Merchant Credit Card" }
              ].map(option => (
                <MenuItem value={option.ID} key={option.ID}>{option.Name}</MenuItem>
              ))}
            </TextField>

            <TextField
              id="terminalShipToType"
              label="Terminal Ship To Type"
              variant="outlined"
              className={classes.sideMargins}
              value={application.terminalShipToType || ""}
              onChange={onApplicationTextChange("terminalShipToType")}
              error={!!errors.terminalShipToType}
              helperText={errors.terminalShipToType}
              margin="normal"
              select
              fullWidth
            >
              {[
                { ID: "AgentOffice", Name: "Agent Office" },
                { ID: "MerchantLocation", Name: "Merchant Location" },
                { ID: "AlternateAddress", Name: "Alternate Address" },
                { ID: "MerchantMailing", Name: "Merchant Mailing" }
              ].map(option => (
                <MenuItem value={option.ID} key={option.ID}>{option.Name}</MenuItem>
              ))}
            </TextField>

            <TextField
              id="terminalShippingService"
              label="Terminal Shipping Service"
              variant="outlined"
              className={classes.sideMargins}
              value={application.terminalShippingService || ""}
              onChange={onApplicationTextChange("terminalShippingService")}
              error={!!errors.terminalShippingService}
              helperText={errors.terminalShippingService}
              margin="normal"
              select
              fullWidth
            >
              {[
                { ID: "Ground", Name: "Ground" },
                { ID: "2Day", Name: "2-Day" },
                { ID: "Overnight", Name: "Overnight" },
                { ID: "PriorityOvernight", Name: "Priority Overnight" },
                { ID: "PrioritySaturday", Name: "Priority Saturday" },
                { ID: "Express", Name: "Express" }
              ].map(option => (
                <MenuItem value={option.ID} key={option.ID}>{option.Name}</MenuItem>
              ))}
            </TextField>

            <TextField
              id="terminalShippingEmail"
              label="Shipping Email (Terminal / Tracking Email)"
              variant="outlined"
              className={classes.sideMargins}
              value={application.terminalShippingEmail}
              onChange={onApplicationTextChange("terminalShippingEmail")}
              error={!!errors.terminalShippingEmail}
              helperText={errors.terminalShippingEmail}
              margin="normal"
              fullWidth
            />

          </div>)
        }
        <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
          Merchant info
        </Typography>

        <div className={classes.inputContainer}>
          <TextField
            id="merchantType"
            label="Merchant Type"
            variant="outlined"
            className={classes.sideMargins}
            value={application.merchantType || ""}
            onChange={onApplicationTextChange("merchantType")}
            error={!!errors.merchantType}
            helperText={errors.merchantType}
            margin="normal"
            select
            fullWidth
          >
            <MenuItem value={"Retail"} key="Retail">Retail</MenuItem>
            <MenuItem value={"MOTO"} key="MOTO">MOTO</MenuItem>
            <MenuItem value={"Internet"} key="Internet">Internet</MenuItem>
            <MenuItem value={"Restaurant"} key="Restaurant">Restaurant</MenuItem>
            <MenuItem value={"RetailWithTip"} key="RetailWithTip">Retail With Tip</MenuItem>
            <MenuItem value={"Lodging"} key="Lodging">Lodging</MenuItem>
            <MenuItem value={"AirlinesCarriers"} key="AirlinesCarriers">Airlines/Carriers</MenuItem>
            <MenuItem value={"CarRental"} key="CarRental">Car Rental</MenuItem>
            <MenuItem value={"CashAdvanceBanksOnly"} key="CashAdvanceBanksOnly">Cash Advance Banks Only</MenuItem>
            <MenuItem value={"CharitableOrg"} key="CharitableOrg">Charitable Org</MenuItem>
            <MenuItem value={"Convenience"} key="Convenience">Convenience</MenuItem>
            <MenuItem value={"CruiseLinesSteamships"} key="CruiseLinesSteamships">Cruise Lines / Steamships</MenuItem>
            <MenuItem value={"EmergingMarket"} key="EmergingMarket">Emerging Market</MenuItem>
            <MenuItem value={"FastFoodSmallTicket"} key="FastFoodSmallTicket">Fast Food Small Ticket</MenuItem>
            <MenuItem value={"Government"} key="Government">Government</MenuItem>
            <MenuItem value={"InsuranceBillPay"} key="InsuranceBillPay">Insurance-Bill Pay</MenuItem>
            <MenuItem value={"PassengerTransport"} key="PassengerTransport">Passenger Transport</MenuItem>
            <MenuItem value={"PetroleumPayAtPump"} key="PetroleumPayAtPump">Petroleum Pay At Pump</MenuItem>
            <MenuItem value={"PetroleumPayClerk"} key="PetroleumPayClerk">Petroleum Pay Clerk</MenuItem>
            <MenuItem value={"PublicSector"} key="PublicSector">Public Sector</MenuItem>
            <MenuItem value={"RealEstateBillPay"} key="RealEstateBillPay">Real Estate-Bill Pay</MenuItem>
            <MenuItem value={"SIIPSCableRecurring"} key="SIIPSCableRecurring">SIIPS/Cable Recurring</MenuItem>
            <MenuItem value={"SIIPSTelecomRecurring"} key="SIIPSTelecomRecurring">SIIPS/Telecom Recurring</MenuItem>
            <MenuItem value={"Supermarket"} key="Supermarket">Supermarket</MenuItem>
            <MenuItem value={"TimeShare"} key="TimeShare">Timeshare</MenuItem>
            <MenuItem value={"TruckRental"} key="TruckRental">Truck Rental</MenuItem>
            <MenuItem value={"Utility"} key="Utility">Utility</MenuItem>
            <MenuItem value={"QSRRegistrationRequired"} key="QSRRegistrationRequired">QSR Registration Required</MenuItem>
          </TextField>

          <TextField
            id="merchantLocation"
            label="Merchant Location"
            variant="outlined"
            className={classes.sideMargins}
            value={application.merchantLocation || ""}
            onChange={onApplicationTextChange("merchantLocation")}
            error={!!errors.merchantLocation}
            helperText={errors.merchantLocation}
            margin="normal"
            select
            fullWidth
          >
            <MenuItem value={"Storefront"} key="Storefront">Storefront</MenuItem>
            <MenuItem value={"Office"} key="Office">Office</MenuItem>
            <MenuItem value={"Home"} key="Home">Home</MenuItem>
            <MenuItem value={"Warehouse"} key="Warehouse">Warehouse</MenuItem>
            <MenuItem value={"Other"} key="Other">Other</MenuItem>
          </TextField>
          </div>

          <div className={classes.inputContainer}>
          <Autocomplete
            fullWidth
            freeSolo
            value={application.soldProductTypes || ""}
            inputValue={this.state.soldProductTypesInputValue}
            onInputChange={(event, newValue) => {
              this.setState({
                soldProductTypesInputValue: newValue
              });
              if (this.state.productTypesOptions.includes(newValue) || !newValue) {
                onApplicationAutocompleteChange('soldProductTypes')(event, newValue);
              }
              else {
                onApplicationAutocompleteChange('soldProductTypes')(event, '');
              }
            }}
            options={this.state.productTypesOptions}
            renderInput={(params) => 
            <TextField
              {...params}
              id="soldProductTypes"
              label="Type of Product(s)/Service(s) Sold"
              variant="outlined"
              className={classes.sideMargins}
              value={application.soldProductTypes || ""}
              error={!!errors.soldProductTypes}
              helperText={errors.soldProductTypes}
              margin="normal"
            />
            }
          />
          </div>

          <div className={classes.inputContainer}>
            <FormGroup className={classes.differentCheckboxContainer} row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={application.processedCreditCardBefore}
                    onChange={onCheckChange("processedCreditCardBefore")}
                    value="processedCreditCardBefore"
                  />
                }
                label="Have you processed credit cards before? "
              />
            </FormGroup>
          </div>

          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
          Bank Information / Funding Information
        </Typography>

        <div className={classes.inputContainer}>
          <TextField
            id="depositTimeFrame"
            label="Deposit Time Frame"
            variant="outlined"
            className={classes.sideMargins}
            value={application.depositTimeFrame || ""}
            onChange={onApplicationTextChange("depositTimeFrame")}
            error={!!errors.depositTimeFrame}
            helperText={errors.depositTimeFrame}
            margin="normal"
            select
            fullWidth
          >
            <MenuItem value={"Standard"} key="Standard">Standard (1 Day Hold)</MenuItem>
            <MenuItem value={"Alternate"} key="Alternate">Alternate Funding (0 Day Hold)</MenuItem>
            <MenuItem value={"ExpressFunding"} key="ExpressFunding">Express Funding</MenuItem>
            <MenuItem value={"SameDayFunding"} key="SameDayFunding">Same Day Funding</MenuItem>
          </TextField>
        </div>

          <Typography
            className={classes.sectionTitle}
            variant="h5"
            gutterBottom
          >
            % of credit card sales
          </Typography>

          <Divider className={classes.divider} />
          <Fragment>
            <div className={classes.inputContainer}>
              {commonFields.map(field => getFieldTextInput(field, true))}
            </div>
            <Typography
              className={classes.sectionTitle}
              variant="h5"
              gutterBottom
            >
              Pricing
            </Typography>
            <Divider className={classes.divider} />
            <div className={`${classes.inputContainer} ${classes.inputContainerRadio}`}>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  row
                  aria-label="pricing"
                  name="pricingPackage"
                  value={application.customPricingType || pricingOption}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    key={'ic+'}
                    value="IC+"
                    control={<Radio />}
                    label="IC+"
                  />
                  <FormControlLabel
                    key={'3tier'}
                    value="3Tier"
                    control={<Radio />}
                    label="3Tier"
                  />
                  <FormControlLabel
                    key={'flat'}
                    value="Flat"
                    control={<Radio />}
                    label="JCIG Citizens flat rate"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Divider className={classes.divider} />
            {(application.customPricingType || pricingOption) && 
              customCategories[application.customPricingType || pricingOption].map((category) => (
                <Fragment key={category.name}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h5"
                    gutterBottom
                  >
                    {category.name}
                  </Typography>
                  <Divider className={classes.divider} />
                  {
                    chunkArray(getFields(application.customPricingType || pricingOption, category), N).map((fieldRow) => (
                      <div key={fieldRow.inputName} className={classes.inputContainer}>
                        {fieldRow.map(field => getFieldTextInput(field))}
                      </div>
                    ))
                  }
                  <Divider className={classes.divider} />
                </Fragment>
              ))}
          </Fragment>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withFirebase
)(Pricing);
